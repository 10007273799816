import { env } from '../Utils/env'

import  {  useEffect,useContext } from "react";
import { setBoolFn,IAsaSessionState,IAsaSession} from '../types/types'
import { AsaStateContext } from '../components/AsaStateProvider'
import {useAsaQuery,callApiPut } from './AsaApiServices'
import {UseQueryResult, QueryClient,  useQuery, useQueryClient,useMutation,UseMutationResult } from "react-query";

function useAsaSession(setLoading:setBoolFn | undefined=undefined, setError:setBoolFn | undefined=undefined):UseQueryResult<IAsaSession, any>{
    const [state, setState] = useContext(AsaStateContext)
  
    useEffect(()=>{
        const currentUrl = new URL(window.location.href);
        if(!currentUrl.searchParams)
          return;
        const newstate:IAsaSessionState = { ...state }
        newstate.sessionID = parseInt(currentUrl.searchParams.get("sessionid")|| '') || newstate.sessionID;
        newstate.asaConsumerCode = parseInt(currentUrl.searchParams.get("asaConsumerCode") || '') || newstate.asaConsumerCode;
        newstate.versionCode = parseFloat(currentUrl.searchParams.get("version") || '') || newstate.versionCode || 1.08;
        newstate.token = currentUrl.searchParams.get("token") || newstate.token;
        newstate.asaFintechcode = parseInt(currentUrl.searchParams.get("asaFintechCode") || '') || newstate.asaFintechcode;
        if(JSON.stringify(state)!==JSON.stringify(newstate))
            setState(newstate)
    },[window.location.href])
    const res=useAsaQuery<IAsaSession>(['Session',state.sessionID],
      env.REACT_APP_API_URL + `AsaSession?asaSessionCode=${state.sessionID}`
      ,setLoading,setError,
      (v)=>!!(state.sessionID && state.sessionID)
    )
    
    return res;
    
  }
  function useAsaSession_old(setLoading:setBoolFn | undefined=undefined, setError:setBoolFn | undefined=undefined){
    const [state, ] = useContext(AsaStateContext)
    
    const res=useAsaQuery(['Session',state.sessionID],
      env.REACT_APP_API_URL + `AsaSession?asaSessionCode=${state.sessionID}`
      ,setLoading,setError,
      (v)=>!!(state.sessionID && state.sessionID)
    )
    
    return res;
    
  }
  export const updateSessionPages = async( asaState:IAsaSessionState,toUpdate:IAsaSession,extraPages:SessionPage[] | null=null )=>{
    const toUpdateclone={...toUpdate}
    if(toUpdate.sessionPages  ){ 
      toUpdateclone.sessionPages=[...toUpdate.sessionPages]
    }
    if(extraPages){  // we are in mode just add new pages
      toUpdateclone.sessionPages=[...extraPages]
      var pagecount=parseInt(toUpdateclone.pageCount)
      toUpdateclone.sessionPages.forEach((page,idx)=>{
        if(!page.pageNumber){
          page.pageNumber=(pagecount-1+idx).toString()
        }
      })
      toUpdateclone.pageCount+=extraPages.length
    }
    try {
      const response = await callApiPut(
        env.REACT_APP_API_URL + `AsaSession`,
        asaState,
        toUpdateclone
      );
      return response;
    } catch (error) {
      throw error;
    }
    finally {
     
    }
  }
  export const updateSession = async (
    asaState:IAsaSessionState,
    data: any,
    pageNumber: number,
    pageName: string,
    pageTitle: string,
    pageDescription: string,
    pageUrl: string,
    pageStatusMessage: string,
    pageCount:number,
    statusMessage:string,
    sessionName:string ="ASAVAULT-ONEGOAL",
    status:string ="Incomplete",
    pageStatus:string ="Complete"
  ) => {
  
   
    const updateRequest: SessionUpdateRequest = {
      asaSessionCode: `${asaState.sessionID}`,
      asaConsumerCode: asaState.asaConsumerCode??0,
      version: asaState.versionCode.toString(),
      sessionName: sessionName,
      pageCount: pageCount,
      status: status,
      statusMessage: statusMessage,
      sessionPages: [
        {
          asaSessionCode: `${asaState.sessionID}`, // Use the session ID
          pageNumber: pageNumber, // Page number, could be a string or a number depending on your API requirements
          pageName: pageName, // Name of the page (e.g., "Landing")
          pageTitle: pageTitle, // Title of the page (e.g., "Select Package")
          pageDescription: pageDescription, // Description of the page
          pageUrl: pageUrl, // URL of the page
          pageStatus: pageStatus, // Status of the page (e.g., "New")
          pageStatusMessage: pageStatusMessage, // Status message for the page
          pageData: JSON.stringify(data), // Serialize the selected plan data
        },
      ],
    };
  
    try {
  
      const response = await callApiPut(
        env.REACT_APP_API_URL + `AsaSession`,
        asaState,
        updateRequest
      );
     
      return response;
    } catch (error) {
      throw error;
    }
    finally {
     
    }
  };
  interface IUpdateSessionPagesArg{
    asaSession:IAsaSession
    extraPages:SessionPage[] | null
  }
  function useAsaSessionPagesMutation( ):UseMutationResult<any, any, IUpdateSessionPagesArg, any>{
    const  [state, ] = useContext(AsaStateContext)
    const queryclient =useQueryClient()
    const mutation=useMutation<any,any,IUpdateSessionPagesArg,any>({
      mutationFn: (arg:IUpdateSessionPagesArg) =>
         updateSessionPages(state,arg.asaSession,arg.extraPages),
        //TO do validate backend, potentially queryclient.setquerydata(.....)
        onSuccess:(response) =>invalidateAsaSession(queryclient,state.sessionID??0,response)
      })
    return mutation;
  }
  
  function useAsaSessionMutation( ):UseMutationResult<any, any, ISessionUpdateArgs, any>{
    const  [state, ] = useContext(AsaStateContext)
    const queryclient =useQueryClient()
    const mutation=useMutation<any,any,ISessionUpdateArgs,any>({
      mutationFn: (updateSessionArgs:ISessionUpdateArgs  ) =>
         updateSession(
          state,
          updateSessionArgs.data,
          updateSessionArgs.pageNumber,
          updateSessionArgs.pageName,
          updateSessionArgs.pageTitle,
          updateSessionArgs.pageDescription,
          updateSessionArgs.pageUrl,
          updateSessionArgs.pageStatusMessage,
          updateSessionArgs.pageCount,
          updateSessionArgs.statusMessage,
          updateSessionArgs.sessionName,
          updateSessionArgs.status,
          updateSessionArgs.pageStatus,
        ),
        //TO do validate backend, potentially queryclient.setquerydata(.....)
        onSuccess:(response) =>invalidateAsaSession(queryclient,state.sessionID??0,response)
      })
    return mutation;
  }
  const invalidateAsaSession=(queryclient:QueryClient | null,sessionId:number,response:any )=>{
    const queryKey=['Session',sessionId]

    if(!response){
      queryclient?.invalidateQueries({queryKey:queryKey})
      queryclient?.refetchQueries({queryKey:queryKey})
    }
    else{
      queryclient?.setQueriesData(queryKey,response)
    }
  }

export {useAsaSession,useAsaSessionPagesMutation,useAsaSessionMutation}