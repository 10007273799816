import  { useState } from "react";

import "../../assets/css/style.css";
import guac_eclipse from "../../assets/Images/guac_subscr_ellipse.svg";
import guac_money_off from "../../assets/Images/guac_money_off.svg";
import guac_subcr_info from "../../assets/Images/guac_subcr_info.svg";
import rate_ex_diss from "../../assets/Images/emotion/extremelly_dissatisfied.svg";
import rate_diss from "../../assets/Images/emotion/dissatisfied.svg";
import rate_neutr from "../../assets/Images/emotion/neutral.svg";
import rate_sat from "../../assets/Images/emotion/satisfied.svg";
import rate__ex_sat from "../../assets/Images/emotion/extremelly_satisfied.svg";


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import GuacBase from "./GuacBase"

import {useFeedbacks,useFeedbackMutation,useFintechSubscriptions} from '../../services/AsaApiServices'


import "./GuacLanding.css"; // Import your CSS file for animations

interface IPropsSubscriptionInfo{
  onOpenFeedback():void
}
interface IPropsRateSelector{
  rate:number,
  setRate(val:number):void
}
interface IIRateItem{
  rateVal:number,
  text:string,
  imgsrc:string

}
interface IPropsSubscriptionFeedback{
   onSubmitFeedback():void
}
const GuacSubscriptionFeedbackSubmitted=()=>
  <div className="subscription-feedback-submitted-container">
      <div className="subscription-feedback-submitted flex flex-col mt-6 items-center">
        <div className="">
          <p>Thank you!</p> 
          <p></p>
          <p>Your feedback has been recorded.</p>
          
        </div>
      </div>
  </div>

const GuacSubscriptionInfo =({onOpenFeedback}:IPropsSubscriptionInfo)=>{
  const feedbacksquery=useFeedbacks()
  const ftquery=useFintechSubscriptions()
  const isAlreadyFeedback=feedbacksquery.isSuccess && feedbacksquery.data && feedbacksquery.data.length>0
  const isSubscriptionFound=ftquery.isSuccess && ftquery.data && ftquery.data.length>0
  // todo if several subscription returned ?
  const subscriptionName=isSubscriptionFound?ftquery.data[0].subscriptionTitle:
      (ftquery.isFetching?'Loading':'Subcriptions not found')
return(
  <>
    <div className="flex flex-col justify-between">

      <div className="flex flex-col mt-6 items-center">
        <img
            className=""
            src={guac_eclipse}
            alt="%"
          
          />
          <img
            src={guac_money_off}
            style={{marginTop:"-120px"}}
            alt='money'
          />

        <div className="mt-16 subscription-status-cancelled">
          Cancelled
        </div>
        <div className="mt-4 subscription-guac">
          {subscriptionName}
        </div>
      </div>
    </div>
    <>
      <div className="mt-4  subscription-text-box">
        <img className="relative"
        src={guac_subcr_info} style={{width:'24px',height:'24px',top:"7px",left:'7px'}} alt='guac'/>
        <div className="flex flex-col  items-center">
          <div className="flex flex-col subscription-text-header">
            <p>Your free trial will end on</p>
            <p>August 27, 2024</p>
          
          </div>
          <div className="subscription-text-details">
          After that, you will no longer have access and your payment method on file will not be charged. Please help us improve by answering a quick survey about why you canceled.
          </div>
          { !isAlreadyFeedback &&
            <div className="mt-2 subscription-btn-feedback" onClick={(e)=>onOpenFeedback()}>
                Give Feedback
            </div>
          }
        </div>
      </div>
      <div className="mt-4 subscription-btn-reactivate">
          Reactivate Plan
      </div>
    </>  
  </>
)
}
const RateSelector=({rate,setRate}:IPropsRateSelector)=>{
   const rateitems:IIRateItem[]=[
    {rateVal:1,text:"1",imgsrc:rate_ex_diss},
    {rateVal:2,text:"2",imgsrc:rate_diss},
    {rateVal:3,text:"3",imgsrc:rate_neutr},
    {rateVal:4,text:"4",imgsrc:rate_sat},
    {rateVal:5,text:"5",imgsrc:rate__ex_sat}
   ];
   
   const renderRateItem=(item:IIRateItem,idx:number,isSelected:boolean)=>{
    return (
      <div key={idx}>
          <img src={item.imgsrc} 
          alt={item.text} 
          className={"mx-2 subscription-rate-icon"+ (isSelected?' subscription-rate-icon-inverted':'')}
          onClick={(e)=>setRate(item.rateVal)}
          />
      </div>
    )
   }
  return(
    <div className="flex flex-row">
        {rateitems.map((item,idx)=>renderRateItem(item,idx,rate===item.rateVal))}
    </div>
  )
}
const GuacSubscriptionFeedback =({onSubmitFeedback}:IPropsSubscriptionFeedback)=>{
  const [rate,setRate]=useState(-1)
  const [feedback,setFeedback]=useState('')
  const isDisabledFeedback= rate <0  || feedback.length===0
  const feedbackMutation=useFeedbackMutation()
  const handleSubmit=async ()=>{
    if(isDisabledFeedback)
      return
    try{
      await feedbackMutation.mutateAsync({rate:rate,msg:feedback})
    }
    catch(err){
      //to do
      console.log(err)
    }
    onSubmitFeedback()
  }
return(
  <>

    <div className="flex flex-col  items-center">
      <div className="flex flex-col subscription-feedback-head mt-10">
        Rate your experience with Guac.
      </div>
      <div className="flex flex-row mt-4">
        <div className="flex flex-row mx-2">bad</div>
        <RateSelector rate={rate} setRate={setRate}></RateSelector>
        <div className="flex flex-row mx-2">good</div>
      </div>
      <div className="subscription-feedback-text mt-8">
        <textarea className="subscription-feedback-input" 
        placeholder="Share your thoughts here..."
        value={feedback}
        onChange={(e)=>setFeedback(e.target.value)}
        ></textarea>
      </div>
      <div className={"mt-4 subscription-btn-submitfeedback" +(isDisabledFeedback?' subscription-btn-submitfeedback-disabled':'')}
          onClick={()=>handleSubmit()}>
        Submit Feedback
      </div>
    </div>
  </>
)

}
export default function GuacSubscription() {
 
  const [isFeedbackOpen,setFeedbackOpen]=useState(false)
  const [isFeedbackSubmitted,setFeedbackSubmitted]=useState(false)
 

  const onFeedbackSubmitted=()=>{
    setFeedbackSubmitted(true)
    setFeedbackOpen(false)
    setTimeout(() =>  setFeedbackSubmitted(false), 5000); 
  }
  return (
    <>
       {isFeedbackSubmitted && <GuacSubscriptionFeedbackSubmitted/>}
       <GuacBase>
       {!isFeedbackOpen &&
            <GuacSubscriptionInfo onOpenFeedback={()=>setFeedbackOpen(true)}></GuacSubscriptionInfo>
          }
          {isFeedbackOpen &&
            <GuacSubscriptionFeedback onSubmitFeedback={()=>onFeedbackSubmitted()}></GuacSubscriptionFeedback>
          }
       </GuacBase>
    </>

  );
}
