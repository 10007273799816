import axios, {AxiosError} from "axios";

import { env } from '../Utils/env'
import {UseQueryResult, QueryClient,  useQuery, useQueryClient,useMutation,UseMutationResult } from "react-query";
import  {  useEffect,useContext } from "react";
import {IAsaResponse, setBoolFn,IAsaSessionState,validateFn,PaymentMethod,IFeedback,
  BillingAddress,IAsaSession, SessionPage,SubscriptionDetail,Plan} from '../types/types'
import { AsaStateContext } from '../components/AsaStateProvider'
import { sleep } from "react-query/types/core/utils";



const getAsaHeaders = (state:IAsaSessionState) => {
    const headers= {
      Accept: "application/json",
      "Content-Type": "application/json",
       asaConsumerCode: state.asaConsumerCode,
      X_ASA_version: state.xAsaVersion,
      "Ocp-Apim-Subscription-Key": env.REACT_APP_SUBSCRIPTION,
      AsaFintechCode: state.asaFintechcode,
      requestid:`PWA-${new Date().getTime()}`,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
     
    };
    console.log('getAsaHeaders state',state)
    if(state.token && state.token.length>0) 
      headers.Authorization= 'Bearer ' + state.token
    console.log('getAsaHeaders headers',headers)
    return headers
  };
  async function callApiGet<TData>  (url:string,state:IAsaSessionState ,checkstatusCode:boolean=true ){
   
    const headers=  getAsaHeaders(state);
       const {data} = await axios.get<IAsaResponse<TData>>(
            url,
        {
            headers  :{...headers},
        }
    )
    if(checkstatusCode && data.status!==200 )
         throw new Error(data.message);
    return data.data;
  }
  async function callApiPost<TData>  (url:string,state:IAsaSessionState ,body:any, checkstatusCode:boolean=true ):Promise<TData>{
   
    const headers=  getAsaHeaders(state);
    const {data} = await axios.post<IAsaResponse<TData>>(
        url,body,
        {
            headers  :{...headers},
        }
    )
    
    if(checkstatusCode && data.status!==200 )
         throw new Error(data.message);
    return data.data;
  }
  async function callApiPut<TData>  (url:string,state:IAsaSessionState ,body:any, checkstatusCode:boolean=true ):Promise<TData>{
   
    const headers=  getAsaHeaders(state);
    const {data} = await axios.put<IAsaResponse<TData>>(
        url,body,
        {
            headers  :{...headers},
        }
    )
    
    if(checkstatusCode && data.status!==200 )
         throw new Error(data.message);
    return data.data;
  }

  function useAsaQuery<TData>(keys:any,
        query:any,
        setLoading:setBoolFn | undefined=undefined,
        setError:setBoolFn | undefined=undefined,
        validate:validateFn =(v)=>true
      ):UseQueryResult<TData, any>{
    const [state, ] = useContext(AsaStateContext)

    const queryfn= (typeof query =='string')?()=>callApiGet(query,state):query
    const reactquery= useQuery<TData,AxiosError>({
        queryKey: keys,
        queryFn: async () => {
            if(validate(query))
              return await queryfn();
            return null;
        },
        refetchOnWindowFocus: false,
        refetchOnMount:false,
        staleTime: 100000,
        
      });
    
  
  
    useEffect(() => {
      setLoading && setLoading(reactquery.isFetching)
      setError && setError(reactquery.isError)
    },[reactquery.isError,reactquery.isFetching,setError,setLoading]);
    if(reactquery.isError){
        console.log(reactquery.error)
    }
    return reactquery
  }

  function useActiveSubscriptions(setLoading:setBoolFn | undefined=undefined, setError:setBoolFn | undefined=undefined)
    :UseQueryResult<SubscriptionDetail, any>{
    const [state, ] = useContext(AsaStateContext)
  
    const res=useAsaQuery<SubscriptionDetail>(['ActiveSubscriptions',state.asaConsumerCode,state.asaFintechcode],
      env.REACT_APP_API_URL + `Consumer/ActiveSubscriptions`
      ,setLoading,setError,
      (v)=>!!(state.asaConsumerCode && state.asaFintechcode)
    )
    
    return res;
    
  }
  function useFintechSubscriptions(setLoading:setBoolFn | undefined=undefined, setError:setBoolFn | undefined=undefined)
  :UseQueryResult<SubscriptionDetail, any>{
  const [state, ] = useContext(AsaStateContext)

  const res=useAsaQuery<SubscriptionDetail>(['FintechSubscriptions',state.asaConsumerCode,state.asaFintechcode],
    env.REACT_APP_API_URL + `Consumer/FintechSubscriptions`
    ,setLoading,setError,
    (v)=>!!(state.asaConsumerCode && state.asaFintechcode)
  )
  
  return res;
  
}
export const saveSubscriptionPurchase = async (asaState:IAsaSessionState,body:any)=>{

  const response = await callApiPost(
    env.REACT_APP_API_URL + `subscriptions/PurchaseFreePlan`,
    asaState,
    body
  );
  return response
} 
function useSubscriptionPurchaseMutation( ):UseMutationResult<any, any, any, any>{
  const  [state, ] = useContext(AsaStateContext)
  const queryclient =useQueryClient()
  const queryKeyall=['FintechSubscriptions',state.asaConsumerCode,state.asaFintechcode]
  const queryKeyactive=['ActiveSubscriptions',state.asaConsumerCode,state.asaFintechcode]
  const mutation=useMutation<any,any,any,any>({
    mutationFn: (args ) =>
      saveSubscriptionPurchase(state, args),
      //TO do validate backend, potentially queryclient.setquerydata(.....)
      onSuccess:() =>{
        queryclient.invalidateQueries({ queryKey:queryKeyall})
        queryclient.refetchQueries({queryKey:queryKeyall})
        queryclient.invalidateQueries({ queryKey:queryKeyactive})
        queryclient.refetchQueries({queryKey:queryKeyactive})
      }
    })
  return mutation;
}
function useSubscriptionsPlans(setLoading:setBoolFn | undefined=undefined, setError:setBoolFn | undefined=undefined)
:UseQueryResult<Plan, AxiosError>{
const [state, ] = useContext(AsaStateContext)

const res=useAsaQuery<Plan>(['SubscriptionPlans',state.asaConsumerCode,state.asaFintechcode],
  env.REACT_APP_API_URL + `Fintech/SubscriptionPlans`
  ,setLoading,setError,
  (v)=>!!(state.asaConsumerCode && state.asaFintechcode)
)

return res;

}

function useBillingAddresses(setLoading:setBoolFn | undefined=undefined, setError:setBoolFn | undefined=undefined)
:UseQueryResult<BillingAddress, AxiosError>{
const [state, ] = useContext(AsaStateContext)

const callApi=async()=>{ 
   const addresses= await callApiGet<BillingAddress[]>(`${ env.REACT_APP_API_URL}Consumer/GetConsumerAddress`,state)
   return addresses.filter(x=>x.isBilling === true)
}
const res=useAsaQuery<BillingAddress>(['BillingAddresses',state.asaConsumerCode,state.asaFintechcode],
  callApi
  ,setLoading,setError,
  (v)=>!!(state.asaConsumerCode && state.asaFintechcode)
)

return res;

}


//Fedbacks
  function useFeedbacks(setLoading:setBoolFn | undefined=undefined, setError:setBoolFn | undefined=undefined)
    :UseQueryResult<IFeedback[], AxiosError>{
    const [state, ] = useContext(AsaStateContext)
  
    const res=useAsaQuery<IFeedback[]>(['Feedbacks',state.asaConsumerCode,state.asaFintechcode],
      env.REACT_APP_API_URL + `Feedback`
      ,setLoading,setError,
      (v)=>!!(state.asaConsumerCode && state.asaFintechcode)
    )
    
    return res;
    
  }
  export const saveFeedback = async (asaState:IAsaSessionState,rate:number,msg:string)=>{
    const saveBody:IFeedback={
      asaConsumerCode:asaState.asaConsumerCode,
      asaFintechCode:asaState.asaFintechcode,
      rating:rate,
      feedbackMessage:msg
    } as IFeedback
    const response = await callApiPost(
      env.REACT_APP_API_URL + `Feedback`,
      asaState,
      saveBody
    );
    return response
  }
  function useFeedbackMutation( ):UseMutationResult<any, any, any, any>{
    const  [state, ] = useContext(AsaStateContext)
    const queryclient =useQueryClient()
    const queryKey=['Feedbacks',state.asaConsumerCode,state.asaFintechcode]
    const mutation=useMutation<any,any,any,any>({
      mutationFn: (args ) =>
        saveFeedback(state,args.rate,args.msg),
        //TO do validate backend, potentially queryclient.setquerydata(.....)
        onSuccess:() =>{
          queryclient.invalidateQueries({ queryKey:queryKey})
          queryclient.refetchQueries({queryKey:queryKey})
        }
      })
    return mutation;
  }
// payment methods

function usePaymentMethods(setLoading:setBoolFn | undefined=undefined, setError:setBoolFn | undefined=undefined)
:UseQueryResult<PaymentMethod[], any>{
const [state, ] = useContext(AsaStateContext)

const res=useAsaQuery<PaymentMethod[]>(['PaymentMethods',state.asaConsumerCode,state.asaFintechcode],
  env.REACT_APP_API_URL + `Payment/PaymentMethod`
  ,setLoading,setError,
  (v)=>!!(state.asaConsumerCode && state.asaFintechcode)
)

return res;

}

// function to keep backward compatbility to fill session pages, needed to check out screen (/ListPay)
const getCheckOutSessionPages=async (state:IAsaSessionState):Promise<{pages:SessionPage[],billingAddresses:unknown}>=>{
  try{
      const pages:SessionPage[]=[]
      const paymethods=await callApiGet(`${env.REACT_APP_API_URL}Payment/PaymentMethod`,state) 

      pages.push({
          pageNumber:3,
          pageName:'PaymentMethodList',
          pageTitle:'PaymentMethodList',
          pageDescription:'PaymentMethodList',
          pageUrl:'/PaymentList',
          pageData:  JSON.stringify(paymethods)
      } as SessionPage)
      const billingAddresses=await callApiGet(`${ env.REACT_APP_API_URL}Consumer/GetConsumerAddress`,state) 
      pages.push({
        pageNumber:7,
        pageName:'BillAddressList',
        pageTitle:'BillAddressList',
        pageDescription:'BillAddressList',
        pageUrl:'/onegoal',
        pageData:  JSON.stringify(billingAddresses)
    } as SessionPage)

     const subscriptionsPlans=await callApiGet(`${ env.REACT_APP_API_URL}Fintech/SubscriptionPlans`,state) 
     pages.push({
        pageNumber:1,
        pageName:'GOALS PLANS',
        pageTitle:'GOALS PLANS',
        pageDescription:'USER SELECTED PLAN',
        pageUrl:'/onegoal',
        pageData: JSON.stringify(subscriptionsPlans)
      } as SessionPage)
     
      const fintech = await callApiGet(`${ env.REACT_APP_API_URL}Fintech/All`,state) 
      const filtered_data = fintech.filter(
        (item: any) => item["asaFintechCode"] === state.asaFintechcode
      );

      pages.push({
        pageNumber:91,
        pageTitle:'FintechName',
        pageName:'FintechName',
        pageDescription:'FintechName',
        pageUrl:'/FintechName',
        pageData:  JSON.stringify(filtered_data)//JSON.stringify(fintech.map((item: any) => item["fintechName"]))
      } as SessionPage)
      return {pages:pages,billingAddresses:billingAddresses};
  }
  catch(err){
    console.log(err)
    return {pages:[],billingAddresses:undefined};
  }
}

  export {useAsaQuery,callApiGet,callApiPost,
    useActiveSubscriptions,useFeedbacks,useFeedbackMutation,useFintechSubscriptions,
    useBillingAddresses,useSubscriptionsPlans,useSubscriptionPurchaseMutation,usePaymentMethods,
    callApiPut,getCheckOutSessionPages }